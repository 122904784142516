import './scss/styles.scss';
import '@tabler/core/dist/js/tabler';
// import '@tabler/core';
import autocompleteAjax from "./js/autocomplete-ajax";
import selectAutocomplete from "./js/select-autocomplete";
import ajaxForm from './js/ajax-form';
import cashRegister from './js/cash-register';
import tableChecker from './js/table-checker';
import Apexcharts from 'apexcharts';
import onScan from "onscan.js";
import Sortable from 'sortablejs';

const app = {
    sale: {
        print: () => {
            const modalEl = document.getElementById('modal-print-sale');
            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);
                const baseIframe = modalEl.querySelector('iframe').outerHTML;

                document.querySelectorAll('.page-header .modal-print')
                    .forEach(el => {
                        el.addEventListener('click', (event) => {
                            const iframe = modalEl.querySelector('iframe');
                            iframe.src = el.dataset.url;

                            modal.show();
                        })
                    })

                modalEl.addEventListener('hidden.bs.modal', () => {
                    modalEl.querySelector('iframe').outerHTML = baseIframe;
                });
            }

            const printThermalButton = document.getElementById('thermal-print');

            if (printThermalButton) {

                printThermalButton.addEventListener('click', () => {
                    const ticketData = printThermalButton.dataset.url;
                    const printerApi = printThermalButton.dataset.printer;

                    if (printerApi === 'no_printer_api_url') {
                        return;
                    }

                    fetch(ticketData)
                        .then(response => response.json())
                        .then(json => {
                            fetch(printerApi, {
                                method: 'POST',
                                referrerPolicy: 'unsafe-url',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify(json),
                            }).catch(error => console.error("Error : " + error));
                        })
                        .catch(error => console.error("Error : " + error));
                })
            }
        }, send: () => {
            const modalEl = document.getElementById('modal-send-sale');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);

                document.querySelector('.page-header .modal-send').addEventListener('click', () => {
                    modal.show();
                })
                const form = modalEl.querySelector('form');
                ajaxForm(form);
            }
        }, updateLine: () => {
            const modalEl = document.getElementById('modal-update-line');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);

                document.querySelectorAll('#sale tr.editable-line').forEach(item => {
                    item.addEventListener('click', event => {
                        if (event.srcElement.classList.contains('ti-external-link')) {
                            return;
                        }

                        modal.show();

                        const handleFormModal = (formModal) => {
                            modalEl.querySelector('.modal-content').innerHTML = formModal;
                            modalEl.querySelector('form').addEventListener('submit', event => {
                                event.preventDefault();
                                event.currentTarget.querySelector('button[type=submit]').disabled = true;
                                fetch(event.currentTarget.action, {
                                    method: 'POST', body: new URLSearchParams(new FormData(event.currentTarget)),
                                })
                                    .then(response => {
                                        if (response.redirected) {
                                            window.location.replace(response.url);
                                        } else {
                                            return response.text()
                                        }
                                    })
                                    .then(response => {
                                        if (response) {
                                            handleFormModal(response);
                                        }
                                    })
                                    .catch(error => console.error("Error : " + error));
                            })
                        }

                        fetch(event.currentTarget.dataset.url)
                            .then(response => response.text())
                            .then(response => {
                                handleFormModal(response);
                            })
                            .catch(error => console.error("Error : " + error));
                    })
                })
            }
        }, lineTypeSelector: () => {
            const lineTypeSelector = document.querySelector('.type-line-form select');
            if (lineTypeSelector) {
                lineTypeSelector.addEventListener('change', () => {
                    for (const el of document.getElementsByClassName('line-form')) {
                        el.classList.add('d-none');
                    }
                    document.querySelector('.' + lineTypeSelector.value + '-line-form').classList.remove('d-none');
                    document.querySelector('.' + lineTypeSelector.value + '-line-form').querySelector("input[type=text]").focus()
                })
                lineTypeSelector.dispatchEvent(new Event('change'));
            }
        }, avoidRepost: () => {
            if (window.history.replaceState) {
                window.history.replaceState(null, null, window.location.href);
            }
        }, payment: () => {
            const sale = document.getElementById('sale');
            const settlementCashInput = document.getElementById('settlement_cash');
            const settlementTypeSelect = document.getElementById('settlement_type');

            if (sale && settlementCashInput && settlementTypeSelect) {
                settlementTypeSelect.addEventListener('change', () => {
                    if (settlementTypeSelect.value === 'cash') {
                        settlementCashInput.parentElement.parentElement.classList.remove('d-none');
                    } else {
                        settlementCashInput.parentElement.parentElement.classList.add('d-none');
                    }
                });

                settlementTypeSelect.dispatchEvent(new Event('change'));
            }

            const modalEl = document.getElementById('modal-close-payment');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);

                document.querySelector('#payment .modal-close-payment-toggle').addEventListener('click', () => {
                    modal.show();
                })

                const form = modalEl.querySelector('form');

                ajaxForm(form, null, 'modal-close-payment');
            }
        }, softCancel: () => {
            const modalEl = document.getElementById('modal-credit');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);
                const form = modalEl.querySelector('form');

                document.querySelector('#sale li.toggle-credit-modal')?.addEventListener('click', () => {
                    modal.show();
                });

                modalEl.querySelector('thead input[type=checkbox]')?.addEventListener('change', event => {
                    modalEl.querySelectorAll('tbody input[type=checkbox]').forEach(checkbox => {
                        checkbox.checked = event.currentTarget.checked;
                        checkbox.dispatchEvent(new Event('change'));
                    });
                })

                modalEl.querySelectorAll('tbody tr').forEach(row => {
                    row.querySelector('td.checkbox input[type=checkbox]').addEventListener('change', event => {
                        if (true === event.currentTarget.checked) {
                            row.classList.remove('unchecked');
                        } else {
                            row.classList.add('unchecked');
                            modalEl.querySelector('thead input[type=checkbox]').checked = false;
                        }
                    })
                })

                ajaxForm(form);
            }
        }, ordersToInvoice: () => {
            const ordersToInvoices = document.querySelector('div.orders-to-invoice');

            if (null === ordersToInvoices) {
                return;
            }

            const table = ordersToInvoices.querySelector('table');
            const invoiceButton = ordersToInvoices.querySelector('button.invoice-button');
            tableChecker(table);

            table.querySelectorAll('input[type=checkbox]')
                .forEach(item => {
                    item.addEventListener('change', () => {
                        if (table.querySelectorAll('input[type=checkbox].checker-item:checked').length > 0) {
                            invoiceButton.disabled = false;
                            invoiceButton.classList.remove('disabled');
                        } else {
                            invoiceButton.disabled = true;
                            invoiceButton.classList.add('disabled');
                        }
                    })
                })

            invoiceButton.addEventListener('click', () => {
                const ids = [];
                table.querySelectorAll('input[type=checkbox].checker-item:checked').forEach(item => {
                    ids.push(item.dataset.id);
                })
                window.location.href = invoiceButton.dataset.url + ids.join(',');
            })
        }, dragTableRows: () => {
            const tbody = document.querySelector('#sale-lines-table-sortable tbody');

            if (tbody) {
                const sortUrl = tbody.dataset.sorturl;

                new Sortable(tbody, {
                    handle: '.handle',
                    animation: 150,
                    onEnd: function (event) {
                        const rows = event.from.querySelectorAll('tr');
                        const ids = [];

                        rows.forEach((row, index) => {
                            ids.push(row.dataset.id);
                        });

                        fetch(sortUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ids: ids})
                        }).then(() => {
                            const movedRow = event.item;
                            movedRow.style.backgroundColor = '#b8f69f';

                            setTimeout(() => {
                                movedRow.style.transition = 'background-color 1.5s ease';
                                movedRow.style.backgroundColor = '';
                                setTimeout(() => {
                                    movedRow.style.transition = '';
                                }, 1500);
                            }, 100);
                        })
                    }
                });
            }
        }
    }, provider: {
        orders: () => {
            const form = document.querySelector('#provider-order form.provider-order-line-form');

            if (!form) {
                return;
            }

            const search = form.querySelector('.autocomplete .text');
            const providerReference = form.querySelector('.providerReference');
            const conditioning = form.querySelector('.conditioning');
            const buyAmount = form.querySelector('.buyAmount');
            const defaultVat = form.querySelector('.defaultVat');

            search.addEventListener('itemSelected', (event) => {
                const item = event.detail.object;
                providerReference.value = item.providerReference ?? null;
                conditioning.value = item.quantity ?? 1;
                buyAmount.value = item.unitPrice ?? null;
                defaultVat.value = item.defaultVat ?? 20;
            });
        }
    }, product: {
        resupply: () => {
            const table = document.querySelector('#resupply table');

            if (null === table) {
                return;
            }

            const orderButton = table.querySelector('button.order-button');
            tableChecker(table);
        },
        inventory: () => {
            const modalEl = document.getElementById('modal-update-inventory-line');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);

                document.querySelectorAll('#inventory tr.editable').forEach(item => {
                    item.addEventListener('click', event => {
                        modal.show();

                        const handleFormModal = (formModal) => {
                            modalEl.querySelector('.modal-content').innerHTML = formModal;
                            modalEl.querySelector('form').addEventListener('submit', event => {
                                event.preventDefault();
                                event.currentTarget.querySelector('button[type=submit]').disabled = true;
                                fetch(event.currentTarget.action, {
                                    method: 'POST', body: new URLSearchParams(new FormData(event.currentTarget)),
                                })
                                    .then(response => {
                                        if (response.redirected) {
                                            window.location.replace(response.url);
                                        } else {
                                            return response.text()
                                        }
                                    })
                                    .then(response => {
                                        if (response) {
                                            handleFormModal(response);
                                        }
                                    })
                                    .catch(error => console.error("Error : " + error));
                            })
                        }

                        fetch(event.currentTarget.dataset.url)
                            .then(response => response.text())
                            .then(response => {
                                handleFormModal(response);
                            })
                            .catch(error => console.error("Error : " + error));
                    })
                })

                function allCharactersSame(str) {
                    let n = str.length;
                    for (let i = 1; i < n; i++)
                        if (str[i] !== str[0])
                            return false;

                    return true;
                }

                onScan.attachTo(document);
                document.addEventListener('scan', function (scan) {
                    scan.preventDefault();
                    if (scan.detail.scanCode && false === allCharactersSame(scan.detail.scanCode)) {

                        const url = new URL(
                            document.getElementById('inventory').dataset.urlScan,
                            window.location.origin
                        );

                        new URLSearchParams(window.location.search).forEach((value, key) => {
                            url.searchParams.set(key, value);
                        })

                        url.searchParams.set('barcode', scan.detail.scanCode);

                        location.replace(url);
                    }
                });
            }
        }
    }, settings: {
        users: function () {
            const addUserButton = document.getElementById('add_user_button');
            if (null === addUserButton) {
                return;

            }
            const formUserModal = document.getElementById('form_user_modal');

            addUserButton.addEventListener('click', function (event) {
                fetch(addUserButton.dataset.url)
                    .then(response => response.text())
                    .then(response => formUserModal.querySelector('.modal-content').innerHTML = response)
                    .catch(error => console.error("Error : " + error));
            });
        }
    },
    dashboard: {
        dashboard: () => {
            const asyncIndex = document.getElementById('async-index');

            if (!asyncIndex) {
                return;
            }

            fetch(asyncIndex.dataset.url)
                .then(response => response.text())
                .then(response => {
                    asyncIndex.innerHTML = response;
                    const triggerTabList = document.querySelectorAll('#chart-tabs li')
                    triggerTabList.forEach(triggerEl => {
                        const tabTrigger = new bootstrap.Tab(triggerEl)

                        triggerEl.addEventListener('click', event => {
                            event.preventDefault()
                            tabTrigger.show()
                        })
                    })

                    const chartsData = document.querySelectorAll('.chartData');

                    chartsData.forEach((chartsData, index) => {
                        const data = JSON.parse(chartsData.dataset.chart);
                        const options = {
                            chart: {
                                type: 'area',
                                height: 240,
                                zoom: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false
                                }
                            },
                            colors: ['#4bb45c', '#3579c9'],
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return value.toLocaleString('fr-FR') + ' €';
                                    }
                                },
                            },
                            ...data
                        }

                        const chart = new Apexcharts(document.querySelector("#chart" + (index + 1) + "-el"), options);
                        chart.render();
                    })


                    const form = document.querySelector('form[name=period-selector]');
                    if (form) {
                        form.querySelectorAll('input[type=radio]').forEach(el => {
                            el.addEventListener('change', event => {
                                document.querySelectorAll('.turnover-period').forEach(period => {
                                    period.innerHTML = period.dataset[event.currentTarget.value];
                                })
                            })
                        })
                    }
                });
        }
    },
    cashbook: {
        out: () => {
            const modalEl = document.getElementById('cashbook-out');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);

                document.getElementById('cashbook-out-trigger').addEventListener('click', () => {
                    modal.show();
                })

                const form = modalEl.querySelector('form');
                ajaxForm(form);
            }
        },
        close: () => {
            const modalEl = document.getElementById('cashbook-close');

            if (modalEl) {
                const modal = bootstrap.Modal.getOrCreateInstance(modalEl);

                document.getElementById('cashbook-close-trigger').addEventListener('click', () => {
                    modal.show();
                })

                const form = modalEl.querySelector('form');
                ajaxForm(form);
            }
        }
    },
    thirdParty: {
        emails: () => {
            const button = document.querySelector('button.add_email_button');

            const removeemailButton = () => {
                document
                    .querySelectorAll('button.remove_email_button')
                    .forEach((button) => {
                        button.addEventListener('click', (e) => {
                            e.preventDefault();
                            button.parentElement.parentElement.parentElement.remove();
                        });

                    })
            }

            removeemailButton();

            if (button) {
                button.addEventListener("click", (e) => {
                    const collectionHolder = document.querySelector('.' + e.currentTarget.dataset.collectionHolderClass);

                    const item = document.createElement('li');

                    item.innerHTML = collectionHolder
                        .dataset
                        .prototype
                        .replace(
                            /__name__/g,
                            collectionHolder.dataset.index
                        );

                    collectionHolder.appendChild(item);

                    collectionHolder.dataset.index++;

                    removeemailButton();
                })
            }
        },
        vehicles: () => {
            const button = document.querySelector('button.add_vehicle_button');

            const removeVehicleButton = () => {
                document
                    .querySelectorAll('button.remove_vehicle_button')
                    .forEach((button) => {
                        button.addEventListener('click', (e) => {
                            e.preventDefault();
                            button.parentElement.parentElement.parentElement.remove();
                        });

                    })
            }

            removeVehicleButton();

            if (button) {
                button.addEventListener("click", (e) => {
                    const collectionHolder = document.querySelector('.' + e.currentTarget.dataset.collectionHolderClass);

                    const item = document.createElement('li');

                    item.innerHTML = collectionHolder
                        .dataset
                        .prototype
                        .replace(
                            /__name__/g,
                            collectionHolder.dataset.index
                        );

                    collectionHolder.appendChild(item);

                    collectionHolder.dataset.index++;

                    removeVehicleButton();
                })
            }
        },
        emailChooser: () => {
            document.querySelectorAll('.email-chooser')
                .forEach(el => {
                    const input = el.querySelector('input[type=text]');

                    el.querySelectorAll('button')
                        .forEach(button => {
                            button.addEventListener('click', () => {
                                input.value = button.innerText;
                            })
                        })
                })
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    app.dashboard.dashboard();
    app.sale.avoidRepost();
    app.sale.lineTypeSelector();
    app.sale.updateLine();
    app.sale.print();
    app.sale.send();
    app.sale.payment();
    app.sale.ordersToInvoice();
    app.sale.softCancel();
    app.sale.dragTableRows();
    app.thirdParty.emails();
    app.thirdParty.vehicles();
    app.thirdParty.emailChooser();
    app.provider.orders();
    app.product.resupply();
    app.product.inventory();
    app.cashbook.out();
    app.cashbook.close();
    app.settings.users();
    cashRegister();
    autocompleteAjax();
    selectAutocomplete();
});
